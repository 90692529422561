<template>
    <section
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Content and Icons Hero</div>
                </div>
            </div>
        </div>

        <div
            class="c-content-icon-hero"
            :class="[
                `t-${componentData.settings.theme}`,
                { 'has-no-padding': componentData.settings.remove_padding == true },
            ]">
            <div class="grid-container">
                <div class="grid-x grid-margin-x align-center">
                    <div class="small-12 medium-10 large-8 cell">
                        <template v-if="title">
                            <h2
                                v-if="componentData.settings.heading_level == 'h2'"
                                class="c-content-icon-hero__title wow animate__animated animate__fadeIn"
                                v-html="title"></h2>

                            <h3
                                v-else-if="componentData.settings.heading_level == 'h3'"
                                class="c-content-icon-hero__title wow animate__animated animate__fadeIn"
                                v-html="title"></h3>

                            <div
                                v-else
                                class="c-content-icon-hero__title wow animate__animated animate__fadeIn"
                                v-html="title"></div>
                        </template>

                        <div
                            class="c-content-icon-hero__items"
                            :class="[{ 'has-icons': componentData.icon_type == 'icons' }]">
                            <div
                                v-for="(item, index) in items"
                                :key="index"
                                class="c-content-icon-hero__item wow animate__animated animate__fadeInUp">
                                <div
                                    v-if="componentData.icon_type == 'icons'"
                                    class="c-content-icon-hero__item-icon"
                                    v-html="item.icon.element"></div>

                                <div class="c-content-icon-hero__item-title" v-html="item.item_title"></div>
                            </div>
                        </div>

                        <div v-if="content" class="c-content-icon-hero__content s-wp-cms" v-html="content"></div>

                        <div v-if="link" class="c-content-icon-hero__link-wrapper">
                            <NuxtLink
                                class="c-content-icon-hero__link wow animate__animated animate__fadeIn"
                                :to="link.url"
                                :target="link.target">
                                <span v-html="link.title"></span>
                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const title = ref(null);
    const content = ref(null);
    const items = ref([]);
    const link = ref(null);

    if (props.componentData.content_source == "global") {
        const globalData = ref(null);

        const getGlobalContentIconHeroData = async () => {
            const { data } = await useFetch(`/api/wp/components/content-icon-hero`);

            // Check if data is empty or undefined
            if (!data || !data.value || data.value.length === 0) {
                // console.log('No data found for the given route:', route.fullPath);

                throw createError({
                    statusCode: 404,
                    statusMessage: "Page Not Found",
                });
            }

            globalData.value = data.value;
        };

        await getGlobalContentIconHeroData();

        // console.log("Global Data: ", globalData.value);

        if (props.componentData.icon_type == "ticks") {
            title.value = globalData.value.ticks_title;
            content.value = globalData.value.ticks_content;
            items.value = globalData.value.ticks_items;
            link.value = globalData.value.ticks_link;
        } else {
            title.value = globalData.value.title;
            content.value = globalData.value.content;
            items.value = globalData.value.items;
            link.value = globalData.value.link;
        }

        if (props.componentData.link_override) {
            link.value = props.componentData.link_override;
        }

        if (props.componentData.disable_cta) {
            link.value = null;
        }
    } else {
        title.value = props.componentData.title;
        content.value = props.componentData.content;
        items.value = props.componentData.items;
        link.value = props.componentData.link;
    }

    // console.log("Data: ", props.componentData);
</script>
